//React
import * as React from "react";
//Components
import TableUsers from "./TableUsers/TableUsers";
import Footer from "../../Global/footer/Footer";
import { useGetUserListQuery } from "../api/apiSlice";
import ButtonAddUser from "./ButtonAddUser";

export default function UsersManagement() {
  const {data: user_data, isSuccess} = useGetUserListQuery();

  if (isSuccess) {
    return (
      <>  
        <TableUsers user_data={user_data.data} />
        <ButtonAddUser/>
        <Footer />
      </>
    );
  } else {
    return <></>
  }
}
