import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./Features/Authentication/authSlice";
import { apiSlice } from "./Features/api/apiSlice";
import notificationsReducer from "./Global/notifications/notificationsSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authenticationReducer,
    notifications: notificationsReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
