import React from "react";
import Table from "./Table";
import { Grid } from "@mui/material";

export default function TableUsers(props) {

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
        width: 500,
        minWidth: 80,
      },
      {
        Header: "Email",
        accessor: "email",
        width: 500,
        minWidth: 100,
      },
      {
        Header: "Edit",
        width: 500,
        minWidth: 20,
      },
    ],
    []
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={1} />
        <Grid container id="app-table" item xs={12} sm={10}>
          <Table columns={columns} data={props.user_data} />
        </Grid></Grid>
    </>
  );
}
