import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./apiHelper";
import { concatPathWithParams } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SECRET_API}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: [
    "dem_questions", 
    "type_catagories", 
    "user_list", 
    "user_groups_available",
    "user_details",
    "client_list",
  ],
  endpoints: (builder) => ({

    //!!___________DEM APP___________!!
    getQuestions: builder.query({
      query: (request) => `${process.env.REACT_APP_DEM_URL}/quality_control/questions/?app=${request.app}&ticket_type=${request.ticket_type}`,
      providesTags: ["dem_questions"],
    }),

    getAppTypeCatagories: builder.query({
      query: (request) => concatPathWithParams(`${process.env.REACT_APP_DEM_URL}/quality_control/questions/`, request.queryParams),
      providesTags: ["type_catagories"],
    }),

    getQuestionTemplates: builder.mutation({
      query: (request) => ({
        url: concatPathWithParams(`${process.env.REACT_APP_DEM_URL}/quality_control/questions/`, request.queryParams),
        method: "GET",
        body: request.body,
      }),
      providesTags: ["question_templates"],
    }),

    newTicket: builder.mutation({
      query: (request) => ({
        url: `${process.env.REACT_APP_DEM_URL}/quality_control/tickets/`,
        method: "POST",
        body: request.body,
      }),
    }),

    //!!___________USER PORTAL___________!!
    newUser: builder.mutation({
      query: (request) => ({
        url: `auth/user_details/`,
        method: "POST",
        body: request.body,
      }),
      invalidatesTags: ["user_list"],
    }),

    getUserDetails: builder.query({
      query: (request) => concatPathWithParams(`auth/user_details/`, request.queryParams),
      providesTags: ["user_details"],
    }),

    updateUserDetails: builder.mutation({
      query: (request) => ({
        url: `auth/user_details/`,
        method: "PATCH",
        body: request.body,
      }),
      invalidatesTags: ["user_list"],
    }),

    getUserList: builder.query({
      query: () => ({
        url: `auth/user_list_view/`,
      }),
      providesTags: ["user_list"],
    }),

    getUserGroupsAvailable: builder.query({
      query: () => ({
        url: `auth/user_groups_available/`,
      }),
      providesTags: ["user_groups_available"],
    }),

    deleteUser: builder.mutation({
      query: (request) => ({
        url: concatPathWithParams(`auth/user_details/`, request.body),
        method: "DELETE",
        body: request.body,
      }),
      invalidatesTags: ["user_list"],
    }),

    //!!___________DBMS___________!!
    getClientList: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_DBMS_API}/sales/clients/`,
      }),
      providesTags: ["client_list"],
    }),

  }),
});

export const { 
  useGetQuestionsQuery,
  useGetAppTypeCatagoriesQuery,
  useGetQuestionTemplatesMutation,
  useNewTicketMutation,
  useNewUserMutation,
  useGetUserListQuery,
  useGetUserGroupsAvailableQuery,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useGetClientListQuery,
  useDeleteUserMutation,
  
} = apiSlice;