//React
import * as React from "react";
//Material UI
import Typography from "@mui/material/Typography";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AppsIcon from "@mui/icons-material/Apps";
import BarChartIcon from "@mui/icons-material/BarChart";
import InventoryIcon from "@mui/icons-material/Inventory";
import Shop2Icon from "@mui/icons-material/Shop2";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
//Imports
import Footer from "../../Global/footer/Footer";

export default function DashboardPage() {
    let navigate = useNavigate();

    let app_index = [
        {
            title: "User Management",
            icon: AdminPanelSettingsIcon,
            description: "Add new users and assign roles",
            navigate_function: () => navigate("/userport"),
            middle_padding: 5,
        },
        {
            title: "Digital Engineering Management",
            icon: ConfirmationNumberIcon,
            description: "Manage app development, operation and innovation.",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DEM_URL}`),
            middle_padding: 1,
        },
        {
            title: "Client Apps",
            icon: AppsIcon,
            description: "A collection of Client Facing Apps",
            navigate_function: () => navigate("/clientapps"),
            middle_padding: 9,
        },
        {
            title: "Gateway Raw Data",
            icon: BarChartIcon,
            description: "View raw data from gateways",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/raw-data/gateways`),
            middle_padding: 5,
        },
        {
            title: "SmartNode Communications",
            icon: BarChartIcon,
            description: "View communications summary of SmartNodes",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/smartnode`),
            middle_padding: 5,
        },
        {
            title: "GNode Raw Data",
            icon: BarChartIcon,
            description: "View raw data from Gnodes",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/gnode`),
            middle_padding: 5,
        },
        {
            title: "Database Management System",
            icon: BarChartIcon,
            description: "View Meta and Processed Data",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DBMS_URL}/datapipelines/`),
            middle_padding: 1,
        },
        {
            title: "Inventory management System",
            icon: InventoryIcon,
            description: "Manage Inventory",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_IMS_URL}`),
            middle_padding: 1,
        },
        {
            title: "Procurement management",
            icon: Shop2Icon,
            description: "Procurement Management App",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DBMS_URL}/procurement/`),
            middle_padding: 5,
        },
        {
            title: "Sales Management",
            icon: AttachMoneyIcon,
            description: "Manage your Sales",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_DBMS_URL}/sales/clients/`),
            middle_padding: 5,
        },
        {
            title: "Project Management",
            icon: AccountTreeIcon,
            description: "Manage your Projects",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_PM_URL}/`),
            middle_padding: 5,
        },
        {
            title: "Test Bench",
            icon: AccountTreeIcon,
            description: "Manage Your Test Bench",
            navigate_function: () =>
                (window.location.href = `${process.env.REACT_APP_TB_URL}/`),
            middle_padding: 5,
        },
    ];

    return (
        <>
            <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 1 }}
                columns={{ xs: 2, sm: 4, md: 8 }}
                sx={{ mt: 10, mb: 15 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {app_index.map((item, index) => (
                    <AppCard key={index} app_info={item} />
                ))}
            </Grid>
            <Footer />
        </>
    );
}

function AppCard({ app_info }) {
    return (
        <Grid item align="center" sx={{ mb: 1 }}>
            <Card
                onClick={app_info.navigate_function}
                sx={{
                    maxWidth: 175,
                    maxHeight: 200,
                    minWidth: 150,
                    minHeight: 300,
                    display: "flex",
                    justiyContent: "space-between",
                    flexDirection: "column",
                    "&:hover": {
                        color: "#fff",
                        background:
                            "linear-gradient(45deg, #D42D45 40%, #805088 70%)",
                        cursor: "pointer",
                    },
                }}
                elevation={10}
            >
                <CardContent>
                    <Typography
                        align="center"
                        variant="h6"
                        sx={{ mb: app_info.middle_padding }}
                    >
                        {app_info.title}
                    </Typography>
                    <app_info.icon sx={{ fontSize: 40 }} />
                    <Typography variant="body2">
                        {app_info.description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}
