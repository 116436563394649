//React
import * as React from "react";
//Material UI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import ListItemIcon from "@mui/material/ListItemIcon";
import BarChartIcon from '@mui/icons-material/BarChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ListSubheader from "@mui/material/ListSubheader";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Typography from '@mui/material/Typography';
//Routing
import { useNavigate } from "react-router-dom";
//Components
import { Nav, Bars, NavLink, DivLink } from "./NavBarElements";
import { logout } from "../../Features/Authentication/authSlice";
//Redux
import { useDispatch } from "react-redux";

const drawerWidth = 240;

export default function UsersNavbar(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuLogout = (event) => {
    setanchorElLogout(event.currentTarget);
  };

  const handleCloseLogout = () => {
    setanchorElLogout(null);
  };

  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };


  const handleProfile = () => {
    navigate("/profile");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          height: 50,
          width: 100,
          maxHeight: { xs: 50, md: 100 },
          maxWidth: { xs: 100, md: 200 },
        }}
      />
      
      <nav aria-label="main mailbox folders">
        <List
          subheader={
            <ListSubheader sx={{ color: "#4f4f4f" }}>
              Go Back To
            </ListSubheader>
          }
        > 
          <Divider />
          <ListItem
            style={{ color: "#3F8CC4" }}
            component={NavLink}
            to="/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon sx={{ color: "#4f4f4f" }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>

        </List>
      </nav>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ mb: 15 }}>
        <Nav>
          {/* <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2 }}
          >
            <Bars />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 12 ,fontFamily: 'Arial',
                    background: 'linear-gradient(90deg, #D42D45 40%, #805088 70%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    ml:2}} textAlign="center">
            Neura Technologies
            <Typography variant="h6" component="div" sx={{ flexGrow: 12, fontSize: 17,fontFamily: 'Arial',
                    background: 'linear-gradient(90deg, #D42D45 40%, #805088 70%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    ml:2 }} textAlign="center">
              BusiOpsMan
            </Typography>
          </Typography>
          <DivLink>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar-right"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuLogout}
            >
              <AccountCircleIcon sx={{ color: "#805088" }} />
            </IconButton>
            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLogout)}
              onClose={handleCloseLogout}
            >
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </DivLink>
        </Nav>
        <Box component="nav">
          <Drawer
            container={container}

            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

      </Box>
    </>
  );
}