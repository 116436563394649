import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

export const Nav = styled.nav`
  background: #fff;
  height: 80px;
  width: 100%;
  display: flex;
  position: fixed;
  border-bottom: 3px solid #805088;

  flexDirection: column;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 600px) {
    justify-content: left;

`;

export const NavLink = styled(Link)`
  color: #3F8CC4;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  
  cursor: pointer;
  &.active {
    color: #4f4f4f;
  }
 `;

export const ImgLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  right: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }


    `;

  export const DivLink = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  right: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }


    `;

export const Bars = styled(MenuIcon)`
  color: #805088;
  display: block;
  position: absolute;
  justify-content: center;
  left: 0;
  bottom: 50%;
  transform: translate(100%, 55%);
  font-size: 1.8rem;
  cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 220px;
  margin-right: 220px;

  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 600px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const NavBtn = styled.nav`
  align-items: center;
  display: flex;

  margin-right: 15px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

