import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";
import StagingNotification from './StagingNotification'

function PrivateRoute() {
  const isLoggedIn = useSelector(checkIsLoggedIn);
  return isLoggedIn ? <><Outlet /><StagingNotification/></> : <Navigate to="/login" />;
}

export default PrivateRoute;
