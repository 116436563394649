//React
import * as React from "react";
//Material UI
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AssessmentIcon from '@mui/icons-material/Assessment';
//Imports
import Footer from "../../Global/footer/Footer";

export default function ClientAppsPage() {
  
  let navigate = useNavigate();

  const handleComingSoon = () => {
    navigate("/comingsoon");
  };
  const handleRetail = () => {
    window.location.href = `${process.env.REACT_APP_RETAIL_URL}/`
  }  


  return (
    <>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 1 }}
        columns={{ xs: 2, sm: 4, md: 8 }}
        sx={{ mt: 10 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >

        <Grid item align="center" sx={{ mb: 1 }}>
          <Card
            onClick={handleRetail}
            sx={{
              maxWidth: 175,
              maxHeight: 200,
              minWidth: 150,
              minHeight: 300,
              display: "flex",
              justiyContent: "space-between",
              flexDirection: "column",
              '&:hover':
              {
                color: '#fff',
                background: 'linear-gradient(45deg, #D42D45 40%, #805088 70%)',
                cursor: 'pointer'
              },
            }}
            elevation={10}
          >
            <CardContent>
              <Typography align="center" variant="h6">
                Retail Energy Management App
              </Typography>
              <PhonelinkRingIcon sx={{ fontSize: 40, mt: 5 }} />
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Client Facing App for Neura
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item align="center" sx={{ mb: 1 }}>
          <Card
            onClick={handleComingSoon}
            sx={{
              maxWidth: 175,
              maxHeight: 200,
              minWidth: 150,
              minHeight: 300,
              display: "flex",
              justiyContent: "space-between",
              flexDirection: "column",
              '&:hover':
              {
                color: '#fff',
                background: 'linear-gradient(45deg, #D42D45 40%, #805088 70%)',
                cursor: 'pointer'
              },
            }}
            elevation={10}
          >
            <CardContent>
              <Typography align="center" variant="h6">
                Neura trading App
              </Typography>
              <AssessmentIcon sx={{ fontSize: 40, mt: 1 }} />
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Trading app for Neura
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item align="center" sx={{ mb: 1 }}>
          <Card
            onClick={handleComingSoon}
            sx={{
              maxWidth: 175,
              maxHeight: 200,
              minWidth: 150,
              minHeight: 300,
              display: "flex",
              justiyContent: "space-between",
              flexDirection: "column",
              '&:hover':
              {
                color: '#fff',
                background: 'linear-gradient(45deg, #D42D45 40%, #805088 70%)',
                cursor: 'pointer'
              },
            }}
            elevation={10}
          >
            <CardContent>
              <Typography align="center" variant="h6">
                Neura Bulk re-selling App
              </Typography>
              <ShoppingCartCheckoutIcon sx={{ fontSize: 40, mt: 1 }} />
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                Bulk app for Neura
              </Typography>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
      <Footer />
    </>
  );
}
