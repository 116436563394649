export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getToken() {
  const token = `${getCookie("access")}`;
  return token;
}

export function concatPathWithParams(path, queryParams) {
  let pathWithParams = path;

  if (queryParams && Object.keys(queryParams).length) {
    pathWithParams += `?${_queryParamsToQueryString(queryParams)}`;
  }

  return pathWithParams;
}

function _queryParamsToQueryString(queryParams) {
  return Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function setCookies(response) {
    console.log(response)
    const assigned_user_groups = parseJwt(response.access).user_groups;
    const client_id = parseJwt(response.access).client_id
    const location_id = parseJwt(response.access).location_id
    const installer_company_id = parseJwt(response.access).installer_company_id

    document.cookie = `refresh=${response.refresh};max-age=86400;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
    document.cookie = `access=${response.access};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;

    if(client_id){
      document.cookie = `client_id=${client_id};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
    }
    if(location_id){
      document.cookie = `location_id=${location_id};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
    }
    if(installer_company_id){
      document.cookie = `installer_company_id=${installer_company_id};max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
    }
    assigned_user_groups.forEach((group) => {
        document.cookie = `${group}=true;max-age=57600;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
    });

}

export function clearAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_SECRET_DOMAIN};path=/`;
  }
}
