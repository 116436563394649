import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isSuccess: false,
  successMessage: "",
  isError: false,
  errorMessage: "",
};

export const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showSuccess: (state, action) => {
      state.isSuccess = true;
      state.successMessage = action.payload;
    },
    hideSuccess: (state, action) => {
      state.isSuccess = false;
      state.successMessage = "";
    },
    showError: (state, action) => {
      state.isError = true;
      state.errorMessage = action.payload;
    },
    hideError: (state, action) => {
      state.isError = false;
      state.errorMessage = "";
    },    
  },
});

export const {
  showSuccess,  
  hideSuccess, 
  showError,  
  hideError,
  
} = notificationsSlice.actions;

export const getIsSuccess = (state) => state.notifications.isSuccess;
export const getSuccessMessage = (state) => state.notifications.successMessage;
export const getIsError = (state) => state.notifications.isError;
export const getErrorMessage = (state) => state.notifications.errorMessage;

export default notificationsSlice.reducer;