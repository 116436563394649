//React
import React from "react";
import { useEffect, useState } from 'react';
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
//RTK Query
import {
  useNewUserMutation,
  useGetUserGroupsAvailableQuery,
} from "../../Features/api/apiSlice";
//Components
import theme from "../../Global/theme";

export default function ButtonAddUser() {

  const { data: available_user_groups, isSuccess: isAvailableGroupsSuccess } = useGetUserGroupsAvailableQuery();
  const [addNewUser, { isLoading: add_new_user_loading }] = useNewUserMutation();

  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [selected_user_groups, setSelectedUserGroups] = useState([]);
  const [open, setOpen] = useState(false);

  let state_functions = {setEmail, setFirstName, setLastName, setSelectedUserGroups, setOpen}
  let state_variables = {email, first_name, last_name, selected_user_groups, open}

  const handleClose = () => {
    setOpen(false);
    resetState()
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const body = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      user_groups: selected_user_groups,
    };
    addNewUser({ body: body });
  };

  useEffect(() => {
    if (!add_new_user_loading) {
      setOpen(false);
      resetState()
    }
  }, [add_new_user_loading]);

  const resetState = () => {
    setEmail("")
    setFirstName("")
    setLastName("")
    setSelectedUserGroups([])
  };

  const dialogProps = {
    BackdropProps: { invisible: true },
    open: open,
    onClose: handleClose
  };

  function AddUserButton() {
    return (
      <>
        <Grid container >
          <Grid item sm={1} />
          <Grid item sm={8}>
            <Button sx={theme.mainButton.root} onClick={() => handleClick()}>
              Add a User
            </Button>
          </Grid>
          <Grid item sm={1} />
        </Grid>
      </>
    );
  }

  function DialogTitleComponent() {
    return (
      <>
        <DialogTitle
          sx={{ mb: -8 }}
        >
          <p>Add User</p>
          <ClearIcon sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "#000",
            "&:hover": {
              color: "red",
              cursor: "pointer",
            },
          }} onClick={handleClose} />
        </DialogTitle>
      </>
    );
  }

  function DialogActionsComponent() {
    return (
      <>
        <DialogActions>
          <Button
            type="submit"
            variant="outlined"
            sx={theme.mainButton.root}
            align="middle"
          >
            Add
          </Button>
        </DialogActions>
      </>
    );
  }

  function LoadingDialog() {
    return (
      <>
        <Dialog
          BackdropProps={{ invisible: true }}
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog >
      </>
    );
  }

  if (isAvailableGroupsSuccess) {
    return (
      <>
        <AddUserButton />

        {!add_new_user_loading ? (
          <Dialog {...dialogProps} >

            <DialogTitleComponent />

            <form onSubmit={(e) => handleOnSubmit(e)}>
              <DialogContent>

                <UserDetails
                  state_functions={state_functions}
                  state_variables={state_variables}
                />

                <UserGroups
                state_variables={state_variables}
                state_functions={state_functions}
                available_user_groups={available_user_groups}
                /> 

              </DialogContent>

              <DialogActionsComponent />
            </form>
          </Dialog>
        ) : (
          <LoadingDialog />
        )
        }
      </>
    );
  } else {
    return <></>
  }
}

function UserDetails({ state_functions, state_variables }) {

  return (
    <>
      <TextField
        onChange={(e) => state_functions.setEmail(e.target.value)}
        margin="normal"
        required
        fullWidth
        className="inputRounded"
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        value={state_variables.email || ""}
      />

      <TextField
        onChange={(e) => state_functions.setFirstName(e.target.value)}
        margin="normal"
        required
        fullWidth
        className="inputRounded"
        id="first_name"
        label="First Name"
        name="first_name"
        autoComplete="first_name"
        value={state_variables.first_name || ""}
      />

      <TextField
        onChange={(e) => state_functions.setLastName(e.target.value)}
        margin="normal"
        required
        fullWidth
        className="inputRounded"
        id="last_name"
        label="Last name"
        name="last_name"
        autoComplete="last_name"
        value={state_variables.last_name || ""}
      />
    </>
  );
}

function UserGroups({ state_variables, state_functions, available_user_groups }) {

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      state_functions.setSelectedUserGroups((prevSelectedUserGroups) => [...prevSelectedUserGroups, name]);
    } else {
      state_functions.setSelectedUserGroups((prevSelectedUserGroups) =>
      prevSelectedUserGroups.filter((userGroup) => userGroup !== name)
      );
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{ mt: 2, mb: 2 }}
      >
        User Groups
      </Typography>
      {available_user_groups.data.map((user_group_sub_division) => (
        <Typography
          key={user_group_sub_division.group_name}
          variant="h6"
        >
          {user_group_sub_division.group_name}
          {user_group_sub_division.user_groups.map((user_group) => (
            <Typography
              key={user_group.unique_name}
            >
              {user_group.human_readable_name}
              <Checkbox
                name={user_group.unique_name}
                checked={state_variables.selected_user_groups.includes(user_group.unique_name)}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                style={{
                  color: "#805088",
                }}
              />
            </Typography>
          ))}
          <Divider sx={{ mb: 2 }} />
        </Typography>
      ))}
    </>
  );
}
