//React
import * as React from 'react';
//Material UI
import { Grid } from "@mui/material";
//Components
import coming_soon from '../../static/images/coming_soon.png'

function ComingSoon() {

  return (
    <Grid container alignItems="center" sx={{mt:15}}>
      <Grid item xs={4}/>
      <Grid item xs={6}>
        <img src={coming_soon} width="500" height="400"/>
      </Grid>
      <Grid item xs={2}/>
    </Grid>
  )
}

export default ComingSoon;