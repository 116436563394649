import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkCookiesExist } from "../permissions/checkPermissions";

export default function PermissionRedirection() {

    const busiopsman_permissions_list = [
        "global_super_user",
        "dsm_operator",
        "dsm_testbench",
        "neura_ims_admin",
    ];

    if (checkCookiesExist(busiopsman_permissions_list)) {
        return <Outlet />;
    } else {
        return <Navigate to="/login" />;
    }
}
