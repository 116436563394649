import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { getCookie } from "../../Features/api/apiHelper";
import Typography from '@mui/material/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StagingNotification() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setCookie('staging_aknowledged', 'true', 365);
    };

    const setCookie = (cname, cvalue, exdays) => {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    useEffect(() => {
        const staging_aknowledged = getCookie('staging_aknowledged') === 'true';
        if (!staging_aknowledged) {
            setOpen(true);
        }
    }, []);

    return (
        <div>
            {/* <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="staging-site"
            >
                <DialogTitle>
                    <Typography variant="h3" gutterBottom>
                        This is a STAGING site!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="staging-site">
                        <Typography variant="h4" gutterBottom>
                            This site is only for testing purposes!
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Do not conduct any actual work here.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Your current production is site is located at:
                            www.neura.busiopsman.com
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>I understand</Button>
                </DialogActions>
            </Dialog> */}
        </div>
    );
}
