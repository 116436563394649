import React from 'react';

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function withAllCookiesTrue(WrappedComponent, cookieNames) {
    return class extends React.Component {
        render() {
            const allCookiesTrue = cookieNames.every((cookieName) => {
                const cookieValue = getCookie(cookieName);
                return cookieValue === 'true';
            });

            if (allCookiesTrue) {
                return <WrappedComponent {...this.props} />;
            } else {
                return null;
            }
        }
    };
}

export function withAnyCookieTrue(WrappedComponent, cookieNames) {
    return class extends React.Component {
        render() {
            const anyCookieTrue = cookieNames.some((cookieName) => {
                const cookieValue = getCookie(cookieName);
                return cookieValue === 'true';
            });

            if (anyCookieTrue) {
                return <WrappedComponent {...this.props} />;
            } else {
                return null;
            }
        }
    };
}

export function withNoCookieTrue(WrappedComponent, cookieNames) {
    return class extends React.Component {
        render() {
            const noCookieTrue = cookieNames.every((cookieName) => {
                const cookieValue = getCookie(cookieName);
                return cookieValue !== 'true';
            });

            if (noCookieTrue) {
                return <WrappedComponent {...this.props} />;
            } else {
                return null;
            }
        }
    };
}

export function checkCookiesExist(cookie_list) {
    for (const cookie_name of cookie_list) {
      const cookie_value = getCookie(cookie_name);
      if (typeof cookie_value !== 'undefined') {
        return true;
      }
    }
    return false;
}