//React
import React from "react";
//Routing
import { Routes, Route } from "react-router-dom";
//Components
import LoginPage from "../../Features/Authentication/LoginPage";
import PrivateRoute from "./PrivateRoute";
import PermissionRedirection from "./PermissionRedirection";
import UsersManagement from "../../Features/Userport/UsersManagement"
import DashboardPage from "../../Features/Dashboard/DashboardPage";
import ComingSoon from "../../Features/Dashboard/ComingSoon";
import Notifications from "../../Global/notifications/Notifications";
import DEMButton from "../other/DEMButton"
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";
import ClientAppsPage from "../../Features/Dashboard/ClientAppsPage";
//Components/Navbars
import DashboardPageNavbar from "../navbar/DashboardPageNavbar";
import UsersNavbar from "../navbar/UsersNavbar"
//Redux
import { useSelector } from "react-redux";

export default function MainRouter() {

  return (
    <>
      <ShowDEMButton />
      
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<PermissionRedirection />}>
            <Route exact path="/" element={<><DashboardPageNavbar /><DashboardPage /></>} />
            <Route exact path="/userport" element={<><UsersNavbar /><UsersManagement /></>} />
            <Route exact path="/clientapps" element={<><UsersNavbar /><ClientAppsPage /></>} />
            <Route exact path="/comingsoon" element={<ComingSoon />} />
          </Route>
        </Route>
        <Route exact path="/login" element={<LoginPage />}></Route>
      </Routes>

      <Notifications />
    </>
  );
}

function ShowDEMButton() {

  const isLoggedIn = useSelector(checkIsLoggedIn);

  if (isLoggedIn) {
    return (
      <DEMButton />
    )
  } else {
    return (
      <></>
    )
  }

}
