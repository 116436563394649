//React
import React from "react";
import { useState } from "react";
import { useEffect } from 'react';
//Material UI
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
//RTK Query
import {
  useUpdateUserDetailsMutation,
  useGetUserGroupsAvailableQuery,
  useGetUserDetailsQuery,
  useDeleteUserMutation,
} from "../../../Features/api/apiSlice";
//Components
import theme from "../../../Global/theme";

export default function ButtonEditUser({ user }) {

  const { data: available_user_groups, isSuccess: isAvailableGroupsSuccess } = useGetUserGroupsAvailableQuery();
  const UserQueryParams = {
    user_id: user.id
  };
  const { data: user_details, isSuccess: isGetUserDetailsSuccess } = useGetUserDetailsQuery({ queryParams: UserQueryParams });
  const [updateUser] = useUpdateUserDetailsMutation();
  const [deleteUser] = useDeleteUserMutation();

  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [selected_user_groups, setSelectedUserGroups] = useState([]);
  const [open_edit_user_dialog, setOpenEditUserDialog] = useState(false);
  const [open_delete_user_dialog, setOpenDeleteUserDialog] = useState(false);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedUserGroups((prevSelectedUserGroups) => [...prevSelectedUserGroups, name]);
    } else {
      setSelectedUserGroups((prevSelectedUserGroups) =>
        prevSelectedUserGroups.filter((userGroup) => userGroup !== name)
      );
    }
  };

  const handleClose = () => {
    setOpenEditUserDialog(false);
  };

  const handleClick = () => {
    setOpenEditUserDialog(true);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const body = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      user_groups: selected_user_groups,
      user_id: user.id,
    };
    updateUser({ body: body });
    setOpenEditUserDialog(false);
  };

  const handleDeleteUser = () => {
    const body = {
      user_id: user.id,
    };
    deleteUser({ body: body });
    setOpenDeleteUserDialog(false);
  };

  const extractUniqueNamesFromUserGroups = (user_groups) => {
    let current_user_groups = []
    user_groups.forEach(function (group) {
      current_user_groups.push(group.unique_name);
    });
    return current_user_groups
  };

  useEffect(() => {
    if (isGetUserDetailsSuccess) {
      setemail(user_details.data.email)
      setfirst_name(user_details.data.first_name)
      setlast_name(user_details.data.last_name)
      let current_user_groups = extractUniqueNamesFromUserGroups(user_details.data.user_groups)
      setSelectedUserGroups(current_user_groups)
    }

  }, [isGetUserDetailsSuccess]);

  if (isAvailableGroupsSuccess && isGetUserDetailsSuccess) {
    return (
      <>
        <EditIcon
          onClick={() => handleClick()}
          sx={{ color: "#805088" }}
        />

        <Dialog
          BackdropProps={{ invisible: true }}
          open={open_edit_user_dialog}
          onClose={handleClose}
        >
          <DialogTitle
            sx={{ mb: -8 }}
          >
            <p>Add User</p>
            <ClearIcon sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#000",
              "&:hover": {
                color: "red",
                cursor: "pointer",
              },
            }} onClick={handleClose} />
          </DialogTitle>

          <form onSubmit={(e) => handleOnSubmit(e)}>
            <DialogContent>

              <TextField
                onChange={(e) => setemail(e.target.value)}
                margin="normal"
                required
                fullWidth
                className="inputRounded"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={email || ""}
              />

              <TextField
                onChange={(e) => setfirst_name(e.target.value)}
                margin="normal"
                required
                fullWidth
                className="inputRounded"
                id="first_name"
                label="First Name"
                name="first_name"
                autoComplete="first_name"
                value={first_name || ""}
              />

              <TextField
                onChange={(e) => setlast_name(e.target.value)}
                margin="normal"
                required
                fullWidth
                className="inputRounded"
                id="last_name"
                label="Last name"
                name="last_name"
                autoComplete="last_name"
                value={last_name || ""}
              />

              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 2 }}
              >
                User Groups
              </Typography>

              {available_user_groups.data.map((user_group_sub_division) => (
                <Typography
                  key={user_group_sub_division.group_name}
                  variant="h6"
                >
                  {user_group_sub_division.group_name}
                  {user_group_sub_division.user_groups.map((user_group) => (
                    <Typography
                      key={user_group.unique_name}
                    >
                      {user_group.human_readable_name}
                      <Checkbox
                        name={user_group.unique_name}
                        checked={selected_user_groups.includes(user_group.unique_name)}
                        onChange={handleCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{
                          color: "#805088",
                        }}
                      />
                    </Typography>
                  ))}
                  <Divider sx={{ mb: 2 }} />
                </Typography>
              ))}

            </DialogContent>

            <DialogActions>
              <ButtonDeleteUser user={user}/>
              <Button
                type="submit"
                variant="outlined"
                sx={theme.mainButton.root}
                align="middle"
              >
                Update
              </Button>

            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  } else {
    return <></>
  }

}

function ButtonDeleteUser({ user }) {

  const [deleteUser] = useDeleteUserMutation();
  const [open_delete_user_dialog, setOpenDeleteUserDialog] = useState(false);

  const handleDeleteUser = () => {
    const body = {
      user_id: user.id,
    };
    deleteUser({ body: body });
    setOpenDeleteUserDialog(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpenDeleteUserDialog(true)}
        variant="outlined"
        sx={theme.cancelButtonStyle.root}
      >
        Delete
      </Button>
      <Dialog
          BackdropProps={{ invisible: true }}
          open={open_delete_user_dialog}
          onClose={() => setOpenDeleteUserDialog(false)}
        >
          <DialogTitle
            sx={{ mb: -8 }}
          >
            <p>Delete User</p>

          </DialogTitle>
            <DialogContent>

              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 2 }}
              >
                Are you sure you would like to delete this user?
              </Typography>

            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => setOpenDeleteUserDialog(false)}
                variant="outlined"
                sx={theme.cancelButtonStyle.root}
              >
                No, Cancel
              </Button>
              <Button
                onClick={handleDeleteUser}
                variant="outlined"
                sx={theme.mainButton.root}
              >
                Yes
              </Button>

            </DialogActions>
        </Dialog>
    </>
  )

}
