import React from "react";
import {FooterName, Nav, FooterLink } from "./FooterStyles";
import neura from "../images/LOGO.PNG";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div style={{'backgroundColor': '#fff'}}>
            <Nav>
                <FooterLink src={neura} width="100px" />
                <FooterName>Powered by Cauchy Consult © {currentYear}</FooterName>
            </Nav>
        </div>
    );
};
